import Fade from "react-reveal/Fade";
import newAssets from "../../newAssets/newAssets";
import { FooterTopBox } from "../common";
import FaqSection from "../home/Faq";
import ServicesCommon from "../services/common";
import ServiceThreeContent from "../servicesThree/servicesThreeContent";
import ServicesFiveBanner from "./ServicesFiveBanner";

const ServicesFive = () => {
  return (
    <>
      <Fade bottom>
        <ServicesFiveBanner />
      </Fade>
      <Fade bottom>
        <ServiceThreeContent />
      </Fade>
      <Fade bottom>
        <h2
          className="background"
          style={{ width: "60%", margin: "80px auto 0" }}
        >
          <span style={{ fontSize: "25px" }}>
            Benefits Of Digitization & Business Intelligence <br />
            <span style={{ color: "#c62028", fontSize: "25px" }}>
              {" "}
              For Your Business (H3){" "}
            </span>
          </span>
        </h2>
        <ServicesCommon
          title="Automation & Efficiency"
          description="We help streamline and maintain your business's operation in an optimized infrastructure by implementing the latest digital technologies. 

Companies are turning to third-party logistics in India to enable cloud-based software systems to centralize their operations and save unnecessary costs and time. Automation makes the back-office operations and delivery happen in a time-bound manner without the interference of human resources. This, in turn, limits the errors significantly.
"
          image={newAssets.serviceFive_one}
          left
        />
      </Fade>
      <Fade bottom>
        <ServicesCommon
          title="Supply Chain Visibility"
          description="Real-time tracking and end-to-end visibility allowed by the integration of business intelligence such as GPS is a real game changer for logistics functions by timely notifications in case of any disruptions or delays in the process. Digitization and business intelligence in logistics enable efficient route planning and rerouting of the shipments with the help of data sharing and save time in case of bottlenecks."
          image={newAssets.serviceFive_two}
        />
      </Fade>
      <Fade bottom>
        <ServicesCommon
          title="The Internet Of Things"
          description="The network framework with AI & MI devices completes the entire supply chain functions seamlessly with the help of real-time tracking of containers and getting alerts on the condition and quality of the sensitive goods. It goes without saying that Digitization and integration of business intelligence will play an important role in the future of logistics. "
          image={newAssets.serviceFive_three}
          left
        />
      </Fade>
      <Fade bottom>
        <ServicesCommon
          title="Data Analytics And Reports System"
          description="The integration of advanced logistics technology gives you insight into complete data with the help of data analytics and reports-generating software systems. We help you optimize your business with successful demand forecasting and streamlined inbound and outbound logistics operations through accessing data analytics. Digitized data has the power to impact workflow, operations structure etc."
          image={newAssets.serviceFive_four}
        />
      </Fade>
      <Fade bottom>
        <ServicesCommon
          title="100% Customer Satisfaction"
          description="Not to mention, customer satisfaction is the ultimate goal of all businesses. With the rising competition in the market due to multinational marketplace sellers and DTC brands in India, satisfying customers’ needs became more challenging.
Digitization and business intelligence align the logistics operation with accurate ETAs, timely order tracking updates and end-to-end visibility of the shipped product at every transit. "
          image={newAssets.serviceFive_five}
          left
        />
      </Fade>
      <Fade bottom>
        <div className="centerButton">
          <button style={{ marginTop: "60px" }} className="contact_button">
            <a href="/login">Join Now</a>
          </button>
        </div>
        <FaqSection />
        <div className="centerButton">
          <button style={{ margin: "80px 0" }} className="contact_button">
            <a href="/login">Join Now</a>
          </button>
        </div>
      </Fade>
      <Fade bottom>
        <FooterTopBox />
      </Fade>
    </>
  );
};

export default ServicesFive;
