import React from 'react';
import { logo_admin } from '../../assets/user/img/'
// import { FooterLogo,MainLogo } from '../../assets/images'

const Footer = () => {


    return (
        <footer>
        <p class="text-center"> Copyright © 2022 driverlogistics. All rights reserved</p>
     </footer>


    )
}
export default Footer
