import React from "react";
import { Link } from "react-router-dom";
import newAssets from "../../newAssets/newAssets";

const ServicesFiveBanner = () => {
  const currentBanner = {};
  return (
    <>
      <div className="main_bannerpart" style={{ paddingBottom: "130px" }}>
        <div className="banner_contantpart_outer">
          <div class="banner_contantpart wow fadeInUp" data-wow-delay="1s">
            <h1 style={{ textAlign: "left" }}>
              {currentBanner.title !== null ? (
                <>
                  <p style={{ fontSize: 16 }}>
                    Enhancing Logistics Efficiency With
                  </p>
                  <span class="banner_redfont">Digitization </span>
                  <span> & Business Intelligence</span>
                </>
              ) : (
                <>
                  <p style={{ fontSize: 16 }}>
                    Enhancing Logistics Efficiency With
                  </p>{" "}
                  <br />
                  <span class="banner_redfont">Digitization </span> & Business
                  Intelligence
                </>
              )}
              <br />
              <div
                style={{
                  textAlign: "left",
                  marginBottom: "20px",
                  fontWeight: "200",
                  opacity: "0.6",
                }}
              >
                <p style={{ fontSize: 16 }}>
                  {" "}
                  Specialized in solving problems and providing logistics
                  solutions hustle-free with in-house fulfilment and cloud-based
                  software technologies. Our API-based software tools give
                  accurate business insights for predictive planning and
                  optimization of your entire supply chain.
                </p>
              </div>
              <div class="banner_readmore">
                <Link to="/about-us" class="theme-btn btn-style-one">
                  <span class="btn-title">Contact Us</span>
                </Link>
              </div>
            </h1>
          </div>
          <div className="banner_imagepart wow fadeInUp">
            <img src={newAssets.serviceFiveBanner} alt="banner" />
          </div>
        </div>
      </div>
      <div className="service_contantbox_outer">
        <h2 className="background double take">
          <span>
            {" "}
            Digitization & Integration of Business Intelligence in 3PL{" "}
          </span>
        </h2>
        <div className="mission_section no_after6">
          <p>
            As the business environments are getting volatile and the logistics
            operations complex, the integration of Digitization is crucial for
            business growth and solid customer relationship building. Needless
            to say, how business intelligence enables efficient logistics
            management with the help of in-depth analysis and precise reports on
            various functions involved.
          </p>
        </div>
        <div className="centerButton">
          <button className="contact_button">
            <a href="/login">Join Now</a>
          </button>
        </div>
      </div>
    </>
  );
};

export default ServicesFiveBanner;
