export const TYPES = {
    FETCH_TESTIMONIALS: 'FETCH_TESTIMONIALS',
    FETCH_TESTIMONIALS_REQUEST: 'FETCH_TESTIMONIALS_REQUEST',
    FETCH_TESTIMONIALS_SUCCESS: 'FETCH_TESTIMONIALS_SUCCESS',
    FETCH_TESTIMONIALS_FAILURE: 'FETCH_TESTIMONIALS_FAILURE',

    FETCH_BANNER: 'FETCH_BANNER',
    FETCH_BANNER_REQUEST: 'FETCH_BANNER_REQUEST',
    FETCH_BANNER_SUCCESS: 'FETCH_BANNER_SUCCESS',
    FETCH_BANNER_FAILURE: 'FETCH_BANNER_FAILURE',

    FETCH_BLOG: 'FETCH_BLOG',
    FETCH_BLOG_REQUEST: 'FETCH_BLOG_REQUEST',
    FETCH_BLOG_SUCCESS: 'FETCH_BLOG_SUCCESS',
    FETCH_BLOG_FAILURE: 'FETCH_BLOG_FAILURE',

    FETCH_NEWS: 'FETCH_NEWS',
    FETCH_NEWS_REQUEST: 'FETCH_NEWS_REQUEST',
    FETCH_NEWS_SUCCESS: 'FETCH_NEWS_SUCCESS',
    FETCH_NEWS_FAILURE: 'FETCH_NEWS_FAILURE',

    FETCH_CLIENT: 'FETCH_CLIENT',
    FETCH_CLIENT_REQUEST: 'FETCH_CLIENT_REQUEST',
    FETCH_CLIENT_SUCCESS: 'FETCH_CLIENT_SUCCESS',
    FETCH_CLIENT_FAILURE: 'FETCH_CLIENT_FAILURE',

    FETCH_STATISTICS: 'FETCH_STATISTICS',
    FETCH_STATISTICS_REQUEST: 'FETCH_STATISTICS_REQUEST',
    FETCH_STATISTICS_SUCCESS: 'FETCH_STATISTICS_SUCCESS',
    FETCH_STATISTICS_FAILURE: 'FETCH_STATISTICS_FAILURE',

    POST_SUBSCRIPTION: 'POST_SUBSCRIPTION',
    POST_SUBSCRIPTION_REQUEST: 'POST_SUBSCRIPTION_REQUEST',
    POST_SUBSCRIPTION_SUCCESS: 'POST_SUBSCRIPTION_SUCCESS',
    POST_SUBSCRIPTION_FAILURE: 'POST_SUBSCRIPTION_FAILURE',

    POST_RESUME: 'POST_RESUME',
    POST_RESUME_REQUEST: 'POST_RESUME_REQUEST',
    POST_RESUME_SUCCESS: 'POST_RESUME_SUCCESS',
    POST_RESUME_FAILURE: 'POST_RESUME_FAILURE',

    POST_CONTACT_FORM: 'POST_CONTACT_FORM',
    POST_CONTACT_FORM_REQUEST: 'POST_CONTACT_FORM_REQUEST',
    POST_CONTACT_FORM_SUCCESS: 'POST_CONTACT_FORM_SUCCESS',

    POST_LOGIN: 'POST_LOGIN',
    POST_LOGIN_REQUEST: 'POST_LOGIN_REQUEST',
    POST_LOGIN_SUCCESS: 'POST_LOGIN_SUCCESS',
    POST_LOGIN_FAILURE: 'POST_LOGIN_FAILURE',

    COMMON_FAILURE: 'COMMON_FAILURE',
    CLEAR_TOAST: 'CLEAR_TOAST',

    UPDATE_SEARCH: 'UPDATE_SEARCH',
    FETCH_SEARCH: 'FETCH_SEARCH',

    FETCH_DASHBOARD: 'FETCH_DASHBOARD',
    FETCH_DASHBOARD_REQUEST: 'FETCH_DASHBOARD_REQUEST',
    FETCH_DASHBOARD_SUCCESS: 'FETCH_DASHBOARD_SUCCESS',
    FETCH_DASHBOARD_FAILURE: 'FETCH_DASHBOARD_FAILURE',

}
export const action = (type, payload = {}) => {
    return { type, payload }
}

export const fetchTestimonials = () => action(TYPES.FETCH_TESTIMONIALS)
export const fetchBanner = () => action(TYPES.FETCH_BANNER)
export const fetchBlogs = (data) => action(TYPES.FETCH_BLOG, { data })
export const fetchNews = (data) => action(TYPES.FETCH_NEWS, { data })
export const fetchClients = () => action(TYPES.FETCH_CLIENT)
export const fetchStatitics = () => action(TYPES.FETCH_STATISTICS)

export const clearToast = () => action(TYPES.CLEAR_TOAST)

export const postSubscription = (data) => action(TYPES.POST_SUBSCRIPTION, { data })
export const postResume = (data) => action(TYPES.POST_RESUME, { data })
export const postContactForm = (data) => action(TYPES.POST_CONTACT_FORM, { data })

export const postLogin = (data) => action(TYPES.POST_LOGIN, { data })

export const updateSearch = (data) => action(TYPES.UPDATE_SEARCH, { data })

export const fetchDashboard = (data) => action(TYPES.FETCH_DASHBOARD, { data })