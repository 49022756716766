import React from "react";
import { Link } from "react-router-dom";
import newAssets from "../../newAssets/newAssets";

const ServicesFourBanner = () => {
  const currentBanner = {};
  return (
    <>
      <div className="main_bannerpart" style={{ paddingBottom: "130px" }}>
        <div className="banner_contantpart_outer">
          <div class="banner_contantpart wow fadeInUp" data-wow-delay="1s">
            <h1 style={{ textAlign: "left" }}>
              {currentBanner.title !== null ? (
                <>
                  <p style={{ fontSize: 16 }}>
                    Reliable | Real-time | Futuristic
                  </p>
                  <span class="banner_redfont">Fleet </span>
                  <span>& Transportation Management</span>
                </>
              ) : (
                <>
                  <p style={{ fontSize: 16 }}>
                    Reliable | Real-time | Futuristic
                  </p>{" "}
                  <br />
                  <span class="banner_redfont">Fleet </span>& Transportation
                  Management
                </>
              )}
              <br />
              <div
                style={{
                  textAlign: "left",
                  marginBottom: "20px",
                  fontWeight: "200",
                  opacity: "0.6",
                }}
              >
                <p style={{ fontSize: 16 }}>
                  {" "}
                  An innovative approach to fleet & Transportation Managament
                  with advanced technology induced real-time tracking and data
                  analytics software systems.
                </p>
              </div>
              <div class="banner_readmore">
                <Link to="/about-us" class="theme-btn btn-style-one">
                  <span class="btn-title">Contact Us</span>
                </Link>
              </div>
            </h1>
          </div>
          <div className="banner_imagepart wow fadeInUp">
            <img src={newAssets.serviceFourBanner} alt="banner" />
          </div>
        </div>
      </div>
      <div className="service_contantbox_outer">
        <h2 className="background double take">
          <span> Responsible Fleet Management Services </span>
        </h2>
        <div className="mission_section no_after6">
          <p>
            Integrating the right Fleet & Transportation system is crucial for
            every business. TMS plays a remarkable role in the growth of
            business from operations and overall productivity to customer
            satisfaction.
          </p>
          <p>
            Real-time tracking and cloud based data analysis save you time
            overcoming unaticipated supply chain challenges without physically
            analysing and finding solutions to it.
          </p>
        </div>
        <div className="centerButton">
          <button className="contact_button">
            <a href="/login">Join Now</a>
          </button>
        </div>
      </div>
    </>
  );
};

export default ServicesFourBanner;
