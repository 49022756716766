import logos from "./Group 1737.png";
import DashboardLogo from "./Group 436.png";
import whiteCircle from "./Group 2212.png";
import serviceBanner from "./Group 6472.png";
import coreBusiness from "./Group 6536.png";
import extensiveNetwork from "./Group 7098.png";
import lowOS from "./Group 7090.png";
import labourEqp from "./Group 7092.png";
import api from "./Group 7093.png";
import supplyChain from "./Group 7094.png";
import control from "./Group 7095.png";
import unneccessoryCosts from "./Group 7096.png";
import expertise from "./Group 7102.png";
import flexibility from "./Group 7103.png";
import CostReduction from "./Group 7113.png";
import safeAndSecure from "./Group 7114.png";
import liveTracking from "./Group 7116.png";
import eCom from "./Group 7117.png";
import tms from "./Group 7119.png";
import customerSatisfaction from "./Group 7104.png";
import homeBanner from "./Group 7106.png";
import apostrophe from "./Layer 2.png";
import profile from "./Mask Group 49.png";
import clienteleBanner from "./Group 7107.png";
import serviceThreeBanner from "./Group 10760.png";
import path1 from "./Path 47258.png";
import path2 from "./Path 47560.png";
import serviceFourBanner from "./service1/Group 25435.png";
import serviceFour_one from "./service1/Group 25436.png";
import serviceFour_two from "./service1/Group 25437.png";
import serviceFour_three from "./service1/Group 25438.png";
import serviceFour_four from "./service1/Group 25439.png";
import serviceFour_five from "./service1/Group 25440.png";
import serviceFour_six from "./service1/Group 25441.png";
import serviceFiveBanner from "./service2/Group 25442.png";
import serviceFive_one from "./service2/Group 25443.png";
import serviceFive_two from "./service2/Group 25444.png";
import serviceFive_three from "./service2/Group 25445.png";
import serviceFive_four from "./service2/Group 25446.png";
import serviceFive_five from "./service2/Group 25447.png";

const newAssets = {
  logos,
  whiteCircle,
  serviceBanner,
  coreBusiness,
  extensiveNetwork,
  unneccessoryCosts,
  homeBanner,
  apostrophe,
  expertise,
  customerSatisfaction,
  flexibility,
  clienteleBanner,
  profile,
  path1,
  path2,
  CostReduction,
  safeAndSecure,
  liveTracking,
  eCom,
  tms,
  lowOS,
  labourEqp,
  api,
  supplyChain,
  control,
  serviceThreeBanner,
  DashboardLogo,
  serviceFourBanner,
  serviceFour_one,
  serviceFour_two,
  serviceFour_three,
  serviceFour_four,
  serviceFour_five,
  serviceFour_six,
  serviceFiveBanner,
  serviceFive_one,
  serviceFive_two,
  serviceFive_three,
  serviceFive_four,
  serviceFive_five,
};

export default newAssets;
