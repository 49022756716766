import Fade from "react-reveal/Fade";
import newAssets from "../../newAssets/newAssets";
import { FooterTopBox } from "../common";
import FaqSection from "../home/Faq";
import ServicesCommon from "../services/common";
import ServiceThreeContent from "../servicesThree/servicesThreeContent";
import ServicesFourBanner from "./ServicesFourBanner";

const ServicesFour = () => {
  return (
    <>
      <Fade bottom>
        <ServicesFourBanner />
      </Fade>
      <Fade bottom>
        <ServiceThreeContent />
      </Fade>
      <Fade bottom>
        <h2
          className="background"
          style={{ width: "60%", margin: "80px auto 0" }}
        >
          <span style={{ fontSize: "25px" }}>
            How Your Business Benefit From Our Fleet <br />
            <span style={{ color: "#c62028", fontSize: "25px" }}>
              {" "}
              & Management Service:{" "}
            </span>
          </span>
        </h2>
        <ServicesCommon
          title="Cost Management"
          description="One big reason to consider 3 PL companies for carrying out Fleet & Transportation management is definitely the efficient cost management. Having FMS outsourced, you can save cost on unnecessary maintences and operations. 

It is no doubt that drivers and vehicles for transportation are expensive. But with the help of our TMS services, you can streamline the fleet and transport operation by monitoring and controlling the process with timely finance reports."
          image={newAssets.serviceFour_one}
          left
        />
      </Fade>
      <Fade bottom>
        <ServicesCommon
          title="Safer Mode Of Transportation"
          description="The safety and security of goods you transport is crucial in fleet and transport management process. With proper fleet and transportation management in place, a 3 PL will already have the safety protocols that comply with the rules and regulations of the country under control.
Expert Drivers capable of covering long distance is also an added benefit.

As your 3PL partner, we offer fleet managers to provide safer mode of transportation for your business with installed features to keep the vehicles with goods tracked and protected on the road from overspeed or malpractices."
          image={newAssets.serviceFour_two}
        />
      </Fade>
      <Fade bottom>
        <ServicesCommon
          title="Complete Visibility & Live Monitoring"
          description="Fleet & Transportation management services from us will give you real-time information on the status of your business’s fleet making it easier for you to track. 

The process of tracking and transfer of information made easier with the advent of new fleet management technologies such as GPS tracking.

Gain optimal performance for your business with complete transparency and ability to analyse and predict requirements with reliable and quick reports of the entire day. This accountability and effective management result improved efficiency of your business. 

"
          image={newAssets.serviceFour_three}
          left
        />
      </Fade>
      <Fade bottom>
        <ServicesCommon
          title="Automation & Time Management"
          description="Automation is a necessity today, if you wish to get ahead with the competition in the market.
Logistics solutions are revolutionazing itself with the integration of automation in their complete operation process. 

Automated update of the arrival and departure timing saves time by denoting at right time for vehicle maintenance. The Internet of Things (IoT) uses multiple tools to analyse the data and identify opportunities for the seamless working and maintenance of vehicles for fleet and transportation management. 
"
          image={newAssets.serviceFour_four}
        />
      </Fade>
      <Fade bottom>
        <ServicesCommon
          title="FMS"
          description="We provide you with top-notch fleet management systems to be in control, organise and synchronize various vehicles used for the transportation of goods. 

Our fleet management systems are connected to sensors, GPS and telematics devices to analyse the condition of vehicles, conduct of drivers. Maintenance status, fuel spent etc.  

It interlinks the operations between various departments and maintain the workflow of logistics operation of your company.
"
          image={newAssets.serviceFour_five}
          left
        />
      </Fade>
      <Fade bottom>
        <ServicesCommon
          title="Customer Satisfaction"
          description="Customer satisfaction is the pillar of any business that can make or break your position in the market. And with increased competition and rising no. of options, it is even difficult to maintain the customers satisfied at every step of your business. 

Please remember that the success of your business is highly dependent on the customer satisfaction. With our dedicated fleet management workforce and time bound transportation and delivery of goods, you are making this pillar strong.

Reach out to our logistics experts for a free consultation on how your business can benefit from our  Fleet & Transportation management services. 
"
          image={newAssets.serviceFour_six}
        />
      </Fade>
      <Fade bottom>
        <div className="centerButton">
          <button style={{ marginTop: "60px" }} className="contact_button">
            <a href="/login">Join Now</a>
          </button>
        </div>
        <FaqSection />
        <div className="centerButton">
          <button style={{ margin: "80px 0" }} className="contact_button">
            <a href="/login">Join Now</a>
          </button>
        </div>
      </Fade>
      <Fade bottom>
        <FooterTopBox />
      </Fade>
    </>
  );
};

export default ServicesFour;
